<template>
  <div>
    <div class="box">
      <div class="topBox" ref="search_d">
        <el-row>
          <div style="display:flex;flex-wrap: wrap;">
            <!-- <el-col :xs="6" :sm="8" :lg="4" :span="4" class="col"> -->
            <div class="col">
              <p class="pTitle">访问量</p>
              <div class="smallBox">
                <div class="iconColorOne">
                  <i class="el-icon-user"/>
                </div>
                <div>
                  <div class="number">
                    <div class="top">
                      <span class="numTop">总访问数</span>
                      <span>{{ totalViewCount }}</span>
                    </div>
                    <div class="bottom">
                      <span class="numBom">今日访问数</span>
                      <span>{{ todayViewCount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </el-col> -->
            <!-- <el-col :xs="6" :sm="8" :lg="4" :span="4" class="col"> -->
            <div class="col">
              <p class="pTitle">用户数</p>
              <div class="smallBox">
                <div class="iconColorTwo">
                  <i class="el-icon-user-solid f30"/>
                </div>
                <div style="display:flex;  justify-content: center;">
                  <div class="number">
                    <div class="top">
                      <span class="numTop">总用户数</span>
                      <span>{{ totalMemberCount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </el-col>
            <el-col :xs="6" :sm="8" :lg="4" :span="4" class="col"> -->
            <div class="col">
              <p class="pTitle">产品分类数</p>
              <div class="smallBox">
                <div class="iconColorThree">
                  <i class="el-icon-menu f30"/>
                </div>
                <div style="display:flex;  justify-content: center;">
                  <div class="number">
                    <div class="top">
                      <span class="numTopThree">总产品分类数</span>
                      <span>{{ totalProductCategoryCount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </el-col>
            <el-col :xs="6" :sm="8" :lg="4" :span="4" class="col"> -->
            <div class="col">
              <p class="pTitle">产品数</p>
              <div class="smallBox">
                <div class="iconColorFour">
                  <i class="el-icon-takeaway-box f30"/>
                </div>
                <div style="display:flex;  justify-content: center;">
                  <div class="number">
                    <div class="top">
                      <span class="numTop">总产品数</span>
                      <span>{{ totalProductCount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </el-col>
            <el-col :xs="6" :sm="8" :lg="4" :span="4" class="col"> -->
            <div class="col">
              <p class="pTitle">短视频数量</p>
              <div class="smallBox">
                <div class="iconColorFive">
                  <i class="el-icon-video-camera f30"/>
                </div>
                <div style="display:flex;  justify-content: center;">
                  <div class="number">
                    <div class="top">
                      <span class="numTop">短视频数量</span>
                      <span>{{ totalVideoCount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </el-col>
            <el-col :xs="6" :sm="8" :lg="4" :span="4" class="col"> -->
            <div class="col">
              <p class="pTitle">VR数量</p>
              <div class="smallBox">
                <div class="iconColorSix">
                  <i class="el-icon-video-camera-solid f30"/>
                </div>
                <div style="display:flex;  justify-content: center;">
                  <div class="number">
                    <div class="top">
                      <span class="numTop">总VR数</span>
                      <span>{{ totalVrCount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </el-col> -->
          </div>
        </el-row>
      </div>

      <div class="bottomBox">
        <div>
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="总访问数" name="1">
              <el-row>
                <el-col :span="6">
                  <el-input
                    placeholder="请输入内容"
                    class="input-with-select"
                    clearable
                    v-model="queryInfo.condition.queryKey"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="searchBtn"
                    ></el-button>
                  </el-input>
                </el-col>
              </el-row>
              <el-table
                :data="totalData"
                border
                style="width: 100%"
                tooltip-effect="dark"
                :height="height"
              >
                <el-table-column
                  prop="date"
                  label="序号"
                  type="index"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="memberName"
                  label="访问人名称"
                  width="180"
                >
                  <template slot-scope="scope">{{
                      scope.row.memberName
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="手机号">
                  <template slot-scope="scope">{{ scope.row.phone }}</template>
                </el-table-column>
                <el-table-column prop="logo" label="头像">
                  <template slot-scope="scope">
                    <img :src="scope.row.logo" alt=""/>
                  </template>
                </el-table-column>
                <el-table-column prop="accessTerminal" label="访问终端">
                  <template slot-scope="scope">{{
                      scope.row.accessTerminal
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="loginDate" label="登录时间">
                  <template slot-scope="scope">{{
                      scope.row.loginDate
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="地址">
                  <template slot-scope="scope">{{
                      scope.row.address
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="ipAddress" label="ip地址">
                  <template slot-scope="scope">{{
                      scope.row.ipAddress
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="ipLocation" label="ip所在地">
                  <template slot-scope="scope">{{
                      scope.row.ipLocation
                    }}
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <div class="block">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="queryInfo.currPage"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="queryInfo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="今日访问数" name="2">
              <el-row>
                <el-col :span="6">
                  <el-input
                    placeholder="请输入内容"
                    class="input-with-select"
                    clearable
                    v-model="queryInfo.condition.queryKey"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="getDayList"
                    ></el-button>
                  </el-input>
                </el-col>
              </el-row>
              <el-table
                :data="dayData"
                border
                style="width: 100%"
                tooltip-effect="dark"
                :height="height"
              >
                <el-table-column
                  prop="date"
                  label="序号"
                  type="index"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="memberName"
                  label="访问人名称"
                  width="180"
                >
                  <template slot-scope="scope">{{
                      scope.row.memberName
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="手机号">
                  <template slot-scope="scope">{{ scope.row.phone }}</template>
                </el-table-column>
                <el-table-column prop="logo" label="头像">
                  <template slot-scope="scope">
                    <img :src="scope.row.logo" alt=""/>
                  </template>
                </el-table-column>
                <el-table-column prop="accessTerminal" label="访问终端">
                  <template slot-scope="scope">{{
                      scope.row.accessTerminal
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="loginDate" label="登录时间">
                  <template slot-scope="scope">{{
                      scope.row.loginDate
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="地址">
                  <template slot-scope="scope">{{
                      scope.row.address
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="ipAddress" label="ip地址">
                  <template slot-scope="scope">{{
                      scope.row.ipAddress
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="ipLocation" label="ip所在地">
                  <template slot-scope="scope">{{
                      scope.row.ipLocation
                    }}
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <div class="block">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="queryInfo.currPage"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="queryInfo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="账号登录排名" name="3">
              <el-row>
                <el-col :span="6">
                  <el-input
                    placeholder="请输入内容"
                    class="input-with-select"
                    clearable
                    v-model="queryInfo.condition.queryKey"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="getLoginRanking"
                    ></el-button>
                  </el-input>
                </el-col>
              </el-row>
              <el-table
                :data="LoginRankingData"
                border
                style="width: 100%"
                tooltip-effect="dark"
                :height="height">
                <el-table-column prop="date" label="序号" type="index" width="100">
                </el-table-column>
                <el-table-column prop="memberName" label="访问人名称" width="180">
                </el-table-column>
                <el-table-column prop="phone" label="手机号">
                </el-table-column>
                <el-table-column prop="loginCount" label="登录次数">
                </el-table-column>
                <el-table-column prop="accessTerminal" label="访问终端">
                </el-table-column>
                <el-table-column prop="lastLoginDate" label="上次登录时间">
                </el-table-column>
                <el-table-column prop="address" label="地址">
                </el-table-column>
                <el-table-column prop="ipAddress" label="ip地址">
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <div class="block">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="queryInfo.currPage"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="queryInfo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: '1',
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {},
      },
      // 今日访问数
      dayData: [],
      // 总访问数
      totalData: [],
      LoginRankingData: [],
      totalViewCount: '', //总访问数
      todayViewCount: '', // 今日访问数
      totalMemberCount: '', // 总用户数
      totalProductCategoryCount: '', // 总产品分类数
      totalProductCount: '', // 总产品数
      totalVideoCount: '', // 总短视频数量
      totalVrCount: '', // 总VR数量
      keyword: '',
      name: 1,
      total: 0,
      height: window.innerHeight - 200, // 表格高度
      headerHeight: 0,
    }
  },
  created () {
    this.getTopList()
    this.getTotalList()
    this.getLoginRanking()
  },
  mounted () {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight
      window.addEventListener('resize', this.getHeight())
    })
  },
  methods: {
    //搜索
    searchBtn () {
      this.queryInfo.pageSize = 10
      this.queryInfo.pageCount = 1
      this.queryInfo.currPage = 1
      this.getTotalList()
    },
    // 监听表格高度
    getHeight () {
      this.height = window.innerHeight - this.headerHeight - 256
    },
    // 点击表单切换
    handleClick (tab) {
      if (tab.name == 1) {
        this.getTotalList()
        this.name = 1
      } else if (tab.name == 2) {
        this.getDayList()
        this.name = 2
      } else if (tab.name == 3) {
        this.getLoginRanking()
        this.name = 3
      }
    },

    getTopList () {
      var that = this
      that.$http.post('/report/statisticsData').then(function (response) {
        // console.log(response);
        if (response.data.code == 200) {
          (that.totalViewCount = response.data.data.totalViewCount), //总访问数
            (that.todayViewCount = response.data.data.todayViewCount), // 今日访问数
            (that.totalMemberCount = response.data.data.totalMemberCount), // 总用户数
            (that.totalProductCategoryCount =
              response.data.data.totalProductCategoryCount), // 总产品分类数
            (that.totalProductCount = response.data.data.totalProductCount), // 总产品数
            (that.totalVideoCount = response.data.data.totalVideoCount), // 总短视频数量
            (that.totalVrCount = response.data.data.totalVrCount) // 总VR数量
          // that.$notify.success({
          //   title: "提示",
          //   message: "获取数据成功",
          //   showClose: true,
          // });
        } else {
          that.$notify.info({
            title: '提示',
            message: response.data.message,
            showClose: true,
          })
        }
      })
    },

    // 今日列表数据
    getDayList () {
      var that = this
      that.queryInfo.condition.queryType = '2'
      that.$http
        .post('/memberLoginLog/list', that.queryInfo)
        .then(function (response) {
          console.log(response)
          if (response.data.code == 200) {
            that.dayData = response.data.data.data
            // console.log(that.dayData);
            that.total = response.data.data.count
          } else {
            that.$notify.info({
              title: '提示',
              message: response.data.message,
              showClose: true,
            })
          }
        })
    },

    // 总列表数据
    getTotalList () {
      var that = this
      that.queryInfo.condition.queryType = '1'
      that.$http
        .post('/memberLoginLog/list', that.queryInfo)
        .then(function (response) {
          if (response.data.code == 200) {
            that.totalData = response.data.data.data
            that.total = response.data.data.count
          } else {
            that.$notify.info({
              title: '提示',
              message: response.data.message,
              showClose: true,
            })
          }
        })
    },

    //账号登录排名数据
    getLoginRanking () {
      this.$http.post('/memberLoginLog/loginCount',this.queryInfo)
        .then(res => {
          console.log(res.data)
          if(res.data.code === 200){
            this.LoginRankingData = res.data.data.data
            this.total = res.data.data.count
          } else {
            this.$notify.info({
              title: '提示',
              message: res.data.message,
              showClose: true,
            })
          }
        })
    },

    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val
      if (this.name == 1) {
        this.getTotalList()
      } else if (this.name == 2) {
        this.getDayList()
      } else if (this.name == 3) {
        this.getLoginRanking()
      }
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val
      if (this.name == 1) {
        this.getTotalList()
      } else if (this.name == 2) {
        this.getDayList()
      } else if (this.name == 3) {
        this.getLoginRanking()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.hTitle {
  margin-top: 0px;
  margin-bottom: 10px;
}

.col {
  // height: 100px;
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 10%;
  box-shadow: 5px 5px 5px #dee1e6;
  width: 170px;
  margin-right: 20px;
  margin-top: 10px;
  font-size: 10px;
  // min-width:150px;
}

.iconColorOne {
  background-color: #0662d3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 30px;

  .el-icon-user {
    // margin-left: 10px;
    margin: 0 auto;
    color: #ffffff;
  }
}

.iconColorTwo {
  background-color: #f9cb05;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 30px;

  .el-icon-user-solid {
    margin: 0 auto;
    color: #ffffff;
  }
}

.iconColorThree {
  background-color: #fb8a06;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 30px;

  .el-icon-menu {
    margin: 0 auto;
    color: #ffffff;
  }
}

.iconColorFour {
  background-color: #0acfc1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 30px;

  .el-icon-takeaway-box {
    margin: 0 auto;
    color: #ffffff;
  }
}

.iconColorFive {
  background-color: #07d3dc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 30px;

  .el-icon-video-camera {
    margin: 0 auto;
    color: #ffffff;
  }
}

.iconColorSix {
  background-color: #46b8e3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 30px;

  .el-icon-video-camera-solid {
    margin: 0 auto;
    color: #ffffff;
  }
}

.pTitle {
  margin-bottom: 0;
  margin-top: 8px;
}

.smallBox {
  display: flex;
  margin-top: 8px;
}

.number {
  margin: 3px;
  display: flex;
  flex-direction: column;
  // align-content: center;
  justify-content: center;
}

.numTop {
  margin-right: 28px;
  margin-left: 15px;
  margin-top: 10px;
}

.numTopThree {
  margin-right: 15px;
  margin-left: 10px;
  margin-top: 10px;
}

.numBom {
  margin-right: 13px;
  margin-left: 15px;
  margin-top: 10px;
}

.f30 {
  font-size: 45px;
  margin-left: 8px;
}

@media (max-width: 500px) {
  .col {
    display: none;
  }
}

.bottomBox {
  margin-top: 20px;
  height: 300px;
  width: 100%;
  background-color: #ffffff;
}
</style>
<!-- <template>
  <div>
    <el-row :gutter="40" class="panel-group">
    <el-col :xs="12" :sm="12" :lg="6" class="that.form-col">
      <div class="that.form" >
        <div class="that.form-icon-wrapper icon-people">
           <i class="el-icon-s-custom f30" />
        </div>
        <div class="that.form-description">
          <div class="that.form-text">
            New Visits
          </div>
          <div class="f22">12</div>
        </div>
      </div>
    </el-col>

  </el-row>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
body{
  background-color: #eef1f9;
}
.panel-group {
  margin-top: 18px;
  padding: 0 30px;
  box-sizing: border-box;
  background-color: #ffffff;

  .that.form-col {
    margin-bottom: 32px;
  }

  .that.form {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .that.form-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .that.form-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .that.form-icon {
      float: left;
      font-size: 48px;
    }

    .that.form-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .that.form-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .that.form-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .that.form-description {
    display: none;
  }

  .that.form-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

  }
}
</style> -->
